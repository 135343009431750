body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain; /* Prevent the body from overscrolling */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* styles.css */

/* Apply font-family to all h1 - h4 elements */
h1, h2, h3, h4 {
  font-family: 'Oranienbaum', serif !important; /* Replace with your desired font */
}

/* Apply font-family to all subtitle and body text */
p, span, div, subtitle1, subtitle2, body1, body2 {
  font-family: 'Playfair Display', serif !important; /* Replace with your desired font */
}

/* Apply font-family to all subtitle elements specifically */
.subtitle1, .subtitle2 {
  font-family: 'Playfair Display', serif !important; /* Replace with your desired font */
}

/* Apply font-family to all buttons */
button {
  font-family: 'Oranienbaum', serif !important; /* Replace with your desired font */
}
